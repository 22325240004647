import React from 'react'

const Logo = ({ height = '60', ...props }) => (
  <svg viewBox="0 0 560 186.3" height={height} {...props}>
    <path
      fill="currentColor"
      d="M45.31 94.7c12.29 0 18.92 7 18.92 15.8 0 7.66-4.79 11.73-8.78 13.08v.32c5.83 1.92 9.82 6.39 9.82 14 0 9.81-7 16.84-19.24 16.84s-20.91-5.74-21.3-18.87H38c.08 4.55 2.56 7.59 7.58 7.59 4.23 0 6.55-2.72 6.55-6.55 0-5.19-3.11-7.26-10.22-7.26h-2.58v-11.09h2.55c4.63 0 9.1-1.12 9.1-6.39 0-3.91-2.31-6.06-6-6.06-4.39 0-6 3.19-6.23 6.38H25.44C25.92 101 33.42 94.7 45.31 94.7zm52.36.15c16.6 0 22.42 12.45 22.42 29.53 0 17.24-5.82 29.69-22.42 29.69s-22.43-12.45-22.43-29.69c0-17.08 5.83-29.53 22.43-29.53zm0 12.77c-8.06 0-9 7.82-9 16.76 0 9.26 1 16.92 9 16.92s9-7.66 9-16.92c.01-8.94-.94-16.76-9-16.76zm54-12.77c16.6 0 22.43 12.45 22.43 29.53 0 17.24-5.83 29.69-22.43 29.69s-22.42-12.45-22.42-29.69c.02-17.08 5.84-29.53 22.44-29.53zm0 12.77c-8.06 0-9 7.82-9 16.76 0 9.26.95 16.92 9 16.92s9-7.66 9-16.92c.04-8.94-.92-16.76-8.98-16.76zm67 34.32h-24.2L190 154.31h-7.66L202.45 99h8.38l20 55.31h-7.63zm-12.13-34l-10 28.06h20.12zm42.3 21v25.38h-7.27V98.69h7.27v25.77l23-25.77h9.18l-25.22 27.85 25.46 27.77h-9.5zm60.49-30.24c13.09 0 19.31 7.26 19.31 16.36 0 7.1-3.91 13.88-13.25 16l14 23.3h-8.7l-13.25-22.75h-8.78v22.75h-7.26V98.69zm0 6h-10.7v21.07h10.7c8.22 0 11.81-4.47 11.81-10.69s-3.45-10.41-11.75-10.41zm65.44 37.27h-24.2l-4.47 12.37h-7.66L358.55 99h8.38l20 55.31h-7.63zm-12.13-34l-10 28.03h20.11zm78.76-9.33v55.7h-7.2L405 110v44.29h-7.26V98.61H405l29.21 44.21V98.61zm44.29 6h-22.6v18.6h20.27v6h-20.27v19.15h22.66v6h-29.92V98.61h29.92zm30.65 50.28c-11.17 0-19.08-6.23-19.23-15.4h7.74c.47 4.62 3.75 9.25 11.49 9.25 7 0 11.17-4 11.17-9.25 0-15-30.09-5.43-30.09-26.1 0-9.18 7.43-15.4 18.44-15.4 10.61 0 17.56 5.82 18.43 14.28h-8c-.48-3.75-4-8-10.77-8.06-6-.16-10.69 3-10.69 8.94 0 14.36 30 5.43 30 26 .08 7.87-6.38 15.69-18.43 15.69z"
    />
    <path fill="none" stroke="currentColor" d="M0 185.8h559.52" />
    <path
      fill="currentColor"
      d="M15.91 62.69l9.8-3.48 10.37-6.06 8.27-5.9 7.69-2 4.64-.89 3.42-.84 7.33-4.17 6.84-5.84 17.18-12.7.63-.84.26-3L107 10.12l11.32-2.74 6.59-.16 1.21-.79h4.69l1.1.63h15.44l1.47-.42 12.49-.11 7.63-.89L173.46 0h13l10.85 1.37 21 5.8 7.58.1 10.33 3.37 5.27.21 10 3.27 3.59-2 7.9 6.21 8.11 3.9 8.74.74 26.17-.11 26.33-.94 9.91-4.22 14.33-3.26 5.16-.11h20.54l13.8 2.32h5.59l11.9-1.48L436.32 10l25.07-7.27 3.48 6.11 4.95 4.64 7.8 3.58 2.74 1.05 2.1 4.11 6 3.58 4.32 2.53 4.74 4.95 2.1 4.11 4.53 3.8L526 52.57l34 12.91H.48zm523.55.1L504.9 46.46l-9.37-7.27-.95-2.95-3.9-4-8.85-5.37-2.74-1.87-.63-1.79-.53-1.21-1.58-.95L469 17.7l-6.22-5-2.84-4.22-5.59 5.8 6.11 5.9-3.46 1.63.95 3.37-2.95 1.48-6.46 1.34-1 2.1-14.43 2.61-5.9 3.4-18.54 3.69-11.59 2.31-11.07 3.8-11.37 5.58-9.06 4.32-5.58 1.72-4.74.63 11-7.59L322.33 43l-37.61-11.92-6.54-1.89-15.59-5.48-7.79-6.11-3.27 1.47-6.11-2.32-9.27-2L224.77 12l-7.48-.31-10.85-3.79-12.22-2.42-5.48-1h-13.8l-5.8 8.85-6.63 7.17-6.11 5.58-11.91 7.53-10.32 2.8-3.07-.7-.32-.48h-4L126 36l-2 .12-.67.6h-4.19l-4.31 2.6-20.86-.35-4.38 1.54-16.75 4.7-11 3.56-3.79.86-9.32.64L43 53.3l-13.67 9.49zM325 39.19l37.61 8.64 5.8.74h2.1l27.29-11.38 8-1.58 6.11-.63L427.26 32l3.58-3.16 13.38-1.79 1-2.74 3.78-.71 3.27-.63v-2.42l.94-.21v-1.69l-5.05-3.79 4.21-3.58-.67-1.48-38.56 9.69L398 21.07l-12.75 3.06-9.69 3.37-8.85 4.21 2.21-2.1L380 22.13l5.69-2.43v-1.37h-19.43l-14.33 1.48-10.53 2.53L311.48 35zm-20.12-6.45l15.38-6.29-29.29 1.26zM93.53 34.45h20.7l4.27-2.8 5.53-.24 2.66-.54 4.11.32 2.31.63 4.43-.32 10-4.42 10.32-8 8.14-8.86h-4.42l-12.12.53-2.74.53h-19.61l-1.79.63h-6L99.51 27.34l10.11-12.69h-2.74l-10.43 5-.74 2.74-4.84 4.32-12.43 9.01L73.8 40z"
    />
  </svg>
)

export default React.memo(Logo)
