import client from './client'

const fetchPosts = () => {
  return client.get('/items/posts', {
    fields: [
      'key',
      'title',
      'body',
      'page.key',
      'meta',
      'file.embed',
      'file.data',
      'file.width',
      'file.height',
      'page.key',
      'page.title',
      'images.*',
      'images.file.metadata.*',
      'images.file.data.*',
      'images.file.width',
      'images.file.height',
    ],
  })
}

export default fetchPosts
