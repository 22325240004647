import React from 'react'
import purify from 'dompurify'
import { useInView } from 'react-intersection-observer'
import SanitizedHtml from 'components/SanitizedHtml'
import LogoSlider from './LogoSlider'
import ConditionalWrap from 'components/ConditionalWrap'
import { isWebUri } from 'valid-url'

const Section = ({ title, body, images }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
  })

  window.purify = purify
  window.isWebUri = isWebUri

  return (
    <div className="py-5 container-fluid bg-white">
      <h3 className="text-primary mb-3" ref={ref}>
        {title}
      </h3>
      <SanitizedHtml html={body} />

      {inView && images ? (
        <LogoSlider>
          {images.map((img) => {
            const url = isWebUri(img.file?.metadata?.url)

            return (
              <ConditionalWrap
                key={img.id}
                condition={url}
                wrap={(children) => (
                  <a href={url} target="_blank" rel="noopener noreferrer">
                    {children}
                  </a>
                )}
              >
                <img
                  key={img.id}
                  src={img.file?.data?.full_url}
                  width={img.file?.width}
                  height={img.file?.height}
                />
              </ConditionalWrap>
            )
          })}
        </LogoSlider>
      ) : null}
    </div>
  )
}

Section.displayName = 'SupportersSection'

export default Section
