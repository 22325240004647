import queryString from 'query-string'

const get = (endpoint, query = null) => {
  if (endpoint.charAt(0) === '/') {
    endpoint = endpoint.substr(1)
  }

  const url = queryString.stringifyUrl(
    {
      url: `${API_BASE}/${endpoint}`,
      query,
    },
    { arrayFormat: 'comma' }
  )

  return fetch(url)
    .then((response) => response.json())
    .then((result) => result.data)
}

export default {
  get,
}
