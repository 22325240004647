import React, { Suspense, lazy } from 'react'
import { Route, Switch } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import Spinner from 'components/Spinner'
import ErrorBoundary from 'components/ErrorBoundary'
import Layout from 'components/Layout'
import NotFound from 'components/NotFound'
import 'cookieconsent'
import 'cookieconsent/build/cookieconsent.min.css'
import 'styles/index.scss'

const queryClient = new QueryClient()

const Lodir = lazy(() => import('pages/lodir'))
const Home = lazy(() => import('pages/home'))
const Hverfi = lazy(() => import('components/Hverfi'))
const Gjold = lazy(() => import('pages/gjold'))
const UmAkranes = lazy(() => import('pages/um-akranes'))
const HafaSamband = lazy(() => import('pages/hafa-samband'))
const ThadErStutt = lazy(() => import('pages/thad-er-stutt'))

async function loadPolyfill() {
  await import('intersection-observer')
}

if (typeof window.IntersectionObserver === 'undefined') {
  loadPolyfill()
}

if (window.cookieconsent) {
  window.cookieconsent.initialise({
    palette: {
      popup: {
        background: '#153d5c',
        text: '#ffffff',
      },
      button: {
        background: 'transparent',
        text: '#ffffff',
        border: 'transparent',
      },
    },
    showLink: false,
    content: {
      message: '300akranes.is notar vefkökur til að bæta upplifun notanda.',
      dismiss: 'Loka',
    },
  })
}

const App = () => (
  <QueryClientProvider client={queryClient}>
    <Layout>
      <ErrorBoundary>
        <Suspense fallback={<Spinner />}>
          <Switch>
            <Route path="/" exact>
              <Home />
            </Route>
            <Route path="/lodir/:hverfi(dalbraut)">
              <Hverfi />
            </Route>
            <Route path="/lodir/:hverfi(floahverfid|skogarhverfi|sementsreitur|aedaroddi)">
              <Lodir />
            </Route>
            <Route path="/gjold">
              <Gjold />
            </Route>
            <Route path="/um-akranes">
              <UmAkranes />
            </Route>
            <Route path="/hafa-samband">
              <HafaSamband />
            </Route>
            <Route path="/thad-er-stutt">
              <ThadErStutt />
            </Route>
            <Route path="*" exact>
              <NotFound />
            </Route>
          </Switch>
        </Suspense>
      </ErrorBoundary>
    </Layout>
  </QueryClientProvider>
)

export default App
