import React, { useMemo } from 'react'
import Slider from 'react-slick'
import styled from 'styled-components'
import 'slick-carousel/slick/slick.css'

const LogoContainer = styled.div`
  display: flex !important;
  width: 200px !important;
  height: 130px;
  // border: 1px solid #e9e9e9;
  background: white;
  padding: 25px;
  align-items: center;
  justify-content: center;

  a {
    display: block;
  }

  img {
    width: auto !important;
    height: auto !important;
    max-width: 100%;
    max-height: 80px;
    margin: 0 auto;
  }
`

const SliderItem = styled.div`
  padding: 5px;
`

const slickSettings = {
  dots: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
  arrows: false,
  autoplay: true,
  speed: 5000,
  autoplaySpeed: 0,
  cssEase: 'linear',
}

const LogoSlider = ({ children, ...rest }) => {
  const elements = React.Children.toArray(children)
  const [initialSlide] = React.useState(Math.floor(Math.random() * elements.length))
  const sliderProps = {
    ...slickSettings,
    ...rest,
    initialSlide,
  }

  return (
    <Slider {...sliderProps}>
      {elements.map((el) => (
        <SliderItem key={el.key}>
          <LogoContainer>{React.cloneElement(el, el.props)}</LogoContainer>
        </SliderItem>
      ))}
    </Slider>
  )
}

export default LogoSlider
