import React from 'react'
import Header from 'components/Header'
import Footer from 'components/Footer'
const Layout = ({ children }) => (
  <>
    <Header />
    <section className="wrap position-relative">{children}</section>
    <Footer />
  </>
)

export default Layout
