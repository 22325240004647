import React from 'react'
import styled, { keyframes } from 'styled-components'
import { useTimeout } from 'react-use'

const ScaleAnimation = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }

  45% {
    transform: scale(0.1);
    opacity: 0.7;
  }

  80% {
    transform: scale(1);
    opacity: 1;
  } 
`

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
`

const SpinnerContainer = styled.div`
  span {
    background-color: #153d5c;
    width: 15px;
    height: 15px;
    border-radius: 100%;
    margin: 2px;
    animation-fill-mode: both;
    display: inline-block;
  }

  span:nth-child(1) {
    animation: ${ScaleAnimation} 0.75s -0.24s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
  }
  span:nth-child(2) {
    animation: ${ScaleAnimation} 0.75s -0.12s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
  }
  span:nth-child(3) {
    animation: ${ScaleAnimation} 0.75s 0s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
  }
`

const Spinner = ({ delay = 500 }) => {
  const [isReady] = useTimeout(delay)

  return (
    <Flex>
      {isReady() ? (
        <SpinnerContainer>
          <span></span>
          <span></span>
          <span></span>
        </SpinnerContainer>
      ) : null}
    </Flex>
  )
}

export default Spinner
