import React, { useMemo } from 'react'
import { useQuery } from 'react-query'
import { useInView } from 'react-intersection-observer'
import styled from 'styled-components'
import Logo from 'components/Logo'
import SanitizedHtml from 'components/SanitizedHtml'
import { Section as SupportersSection } from 'components/Supporters'
import fetchPosts from 'api/fetchPosts'

export const Responsive = styled.div`
  width: 100%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 991px) {
    width: 1160px;
    height: 332px;
  }

  @media (min-width: 992px) {
    padding-bottom: 28.6458333333%;
  }
`

export const Content = styled.div`
  font-family: 'GT Walsheim Pro';
  font-size: 1.1rem;
  position: absolute;
  top: 0;
  bottom: 0;

  a,
  a:hover {
    color: white;
  }

  small {
    font-size: 0.9rem;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    @media (min-width: 768px) {
      li {
        display: inline-block;

        &:not(:last-child):after {
          content: '•';
          padding: 0 15px;
        }
      }
    }
  }
`

const Footer = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
  })
  const { data } = useQuery('posts', fetchPosts)
  const [content, supporters] = useMemo(() => {
    return data
      ? [
          data.find((item) => item.key === 'footer'),
          data.find?.((i) => i.key === 'studningsadilar'),
        ]
      : [{}, {}]
  }, [data])

  return (
    <>
      {supporters ? (
        <SupportersSection
          title={supporters.title}
          body={supporters.body}
          images={supporters.images}
        />
      ) : null}
      <footer className="position-relative overflow-hidden bg-primary">
        <Responsive className="position-relative">
          {content?.meta?.embed && inView ? (
            <iframe
              className="w-100 h-100 mx-auto border-0"
              src={`https://player.vimeo.com/video/${content.meta.embed}?autoplay=1&loop=1&background=1`}
            />
          ) : null}
        </Responsive>
        <Content className="d-flex w-100 mx-auto align-items-center justify-content-center text-center text-white">
          <div ref={ref}>
            <Logo />
            <div className="pt-3 pb-4">
              {content.body ? <SanitizedHtml html={content.body} /> : null}
            </div>
            <p className="p-0 m-0">
              <small className="d-block">
                {content?.meta?.disclaimer ? content.meta.disclaimer : null}
              </small>
              <small>
                Vefvinnsla &amp; Hönnun:{' '}
                <a href="https://onno.is" target="_blank" rel="noopener noreferrer">
                  ONNO ehf.
                </a>
              </small>
            </p>
          </div>
        </Content>
      </footer>
    </>
  )
}

export default React.memo(Footer)
