import React from 'react'
import { Container } from 'react-bootstrap'

const NotFound = () => (
  <Container className="py-5">
    <h1>Ekkert fannst hér...</h1>
    <p>Lítur út fyrir að þú hafir týnst, síðan sem þú ert að reyna skoða finnst ekki.</p>
  </Container>
)

export default NotFound
