/* @refresh reset */

import React from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'

class ErrorBoundary extends React.Component {
  state = { hasError: false }
  unregister = null

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location !== prevProps.location && prevState.hasError) {
      this.setState({ hasError: false })
    }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  render() {
    const { props, state } = this

    if (state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="p-5 text-center">
          <h1>Úps!</h1>
          <h4>Eitthvað fór úrskeiðis, reyndu að endurhlaða síðunni.</h4>
        </div>
      )
    }

    return props.children
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
}

export default withRouter(ErrorBoundary)
