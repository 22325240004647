import React, { useMemo } from 'react'
import { Dropdown, Navbar, Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useToggle } from 'react-use'
import styled from 'styled-components'
import Logo from 'components/Logo'

const MobileNavbar = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;

  .navbar-toggler {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 2rem;
  }

  &,
  .navbar-toggler {
    height: 72px;
  }
`

const HoverDropdown = ({ title, items }) => {
  const [show, toggle] = useToggle(false)

  return (
    <Dropdown onMouseEnter={toggle} onMouseLeave={toggle} show={show}>
      <Dropdown.Toggle id={title} as="span" className="nav-link d-block">
        {title}
      </Dropdown.Toggle>
      <Dropdown.Menu renderOnMount={1} align="right">
        {items.map(({ href, eventKey, title }) => (
          <Dropdown.Item key={href} eventKey={eventKey} as={Link} to={href}>
            {title}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}

const Header = () => {
  const dropdownItems = useMemo(
    () => ({
      ibudarlodir: [
        { eventKey: '1.1', title: 'Sementsreitur', href: '/lodir/sementsreitur' },
        { eventKey: '1.2', title: 'Skógarhverfi', href: '/lodir/skogarhverfi' },
        { eventKey: '1.3', title: 'Dalbraut', href: '/lodir/dalbraut' },
      ],
      atvinnulodir: [{ eventKey: 2, title: 'Flóahverfið', href: '/lodir/floahverfid' }],
      akranes: [
        { eventKey: '5.1', title: 'Almennar upplýsingar', href: '/um-akranes' },
        { eventKey: '5.2', title: 'Hafa samband', href: '/hafa-samband' },
        { eventKey: '5.3', title: 'Það er stutt...', href: '/thad-er-stutt' },
      ],
    }),
    []
  )

  return (
    <Navbar collapseOnSelect bg="primary" variant="dark" expand="lg">
      <MobileNavbar className="d-lg-none">
        <Navbar.Toggle />
        <Nav.Link eventKey={1} className="d-inline-block p-0 mx-auto text-white" as={Link} to="/">
          <Logo height="40" />
        </Nav.Link>
      </MobileNavbar>
      <Navbar.Collapse className="justify-content-center">
        <Nav className="align-items-start align-items-lg-end">
          <Nav.Item>
            <HoverDropdown title="Íbúðarlóðir" items={dropdownItems.ibudarlodir} />
          </Nav.Item>
          <Nav.Item>
            <HoverDropdown title="Atvinnulóðir" items={dropdownItems.atvinnulodir} />
          </Nav.Item>
          <Nav.Item>
            <Nav.Link as={Link} eventKey={3} to="/" className="p-0 d-none d-lg-block px-3">
              <Logo height="60" className="text-white" />
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey={4} as={Link} to="/gjold">
              Gjöld
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <HoverDropdown title="Um Akranes" items={dropdownItems.akranes} />
          </Nav.Item>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

export default React.memo(Header)
